export const baseUrl = "http://localhost:8080";

const baseUrl_RELEASE = "https://xs591901.xsrv.jp/api/admin";
const baseUrl_TRN = "https://tran-it.biz/foster/laravel/public/api/admin";
// const baseUrl_DEVELOP = "http://localhost/api/admin";
// const baseUrl_LOCAL = "http://192.168.128.54/api/admin";
const baseUrl_LOCAL = "http://localhost/api/admin";

/**
 * 動作モードの定数
 * RELEASE: 本番モード
 * LOCAL: 開発モード(ローカル環境)
 * TRN: 開発モード(TRN環境)
 */
export const AppModeType = {
  /** 本番モード */
  RELEASE: 1,
  /** 開発モード(ローカル環境) */
  LOCAL: 2,
  /** 開発モード(TRN環境) */
  TRN: 3,
};
export type AppModeType = (typeof AppModeType)[keyof typeof AppModeType];
/**
 * 動作モード
 */
// const appModeType: AppModeType = AppModeType.LOCAL;
const appModeType: AppModeType = AppModeType.RELEASE;
export const appVersion = "2024.10.10.01";

export function APP_MODE_COLOR() {
  switch (appModeType) {
    case AppModeType.LOCAL:
      return "#F00";

    case AppModeType.RELEASE:
      return "#FFF";

    default:
      return "#666";
  }
}

//apiのドメインを設定する。
export function baseURL() {
  let baseUrl: string;

  switch (appModeType) {
    case AppModeType.LOCAL:
      baseUrl = baseUrl_LOCAL;
      break;
    case AppModeType.RELEASE:
      baseUrl = baseUrl_RELEASE;
      break;
    case AppModeType.TRN:
      baseUrl = baseUrl_TRN;
      break;
    default:
      throw new Error("Invalid app mode type");
  }

  return baseUrl;
}
